.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 4 equal columns */
    grid-template-rows: repeat(2, 1fr);
    /* 2 equal rows */
    gap: 12px;
    /* Optional: space between items */
}

.item {
    /* background-color: #4CAF50; */
    /* Green background */
    /* color: white; */
    text-align: center;
    /* padding: 20px; */
    /* border: 1px solid #ddd; */
    /* Optional: border around items */
}

.item5 {
    grid-column: 3 / span 2;
    /* Span columns 3 and 4 */
    grid-row: 1 / span 2;
    /* Span rows 1 and 2 */
}